import { NextSeo } from "next-seo";
import SocialLoginButtons from '@c/SocialLoginButton';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import {
  createUserDocument,
  emailSchema,
  getUserById,
  getValidUserDocument,
  passwordSchema,
} from '@util/firestore/users';
import { formatAuthError } from '@util/index';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { FirebaseError } from 'firebase/app';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import FormInput from './controls/FormInput';
import FormLabel from './controls/FormLabel';

const formSchema = z.object({
  email: emailSchema,
  password: passwordSchema,
});

type Form = z.infer<typeof formSchema>;

const LoginForm = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const {
    loginEmailPassword,
    loginSocial,
    isLoggingIn,
    fetchProviderForEmail,
  } = useAuth();
  const [authError, setAuthError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, formState, watch } = useForm<Form>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const form = watch();
  const errors = formState.errors;

  const login: SubmitHandler<Form> = async () => {
    setIsSubmitting(true);
    const loginResp = await loginEmailPassword(form);
    if (loginResp instanceof FirebaseError) {
      if (loginResp.code === 'auth/wrong-password') {
        const res = await fetchProviderForEmail(form.email);
        if (res) {
          setAuthError(
            `This account was signed up with ${res}. Please sign in with that provider.`
          );
          setIsSubmitting(false);
          return;
        } else {
          setIsSubmitting(false);
          setAuthError(formatAuthError(loginResp));
        }
      }
      setIsSubmitting(false);
      setAuthError(formatAuthError(loginResp));
    } else {
      if (router.query?.redirect) {
        router.push(router.query.redirect.toString());
      } else {
        router.push('/');
      }
    }
  };


  const handleSocialClick = async (s: AuthProvider) => {
    setIsSubmitting(true);
    const res = await loginSocial(s);
    if (res instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(formatAuthError(res));
    } else {
      // check if user doc exists
      const userDoc = await getUserById(res.user.uid);
      if (!userDoc) {
        // if not, create one
        const docData = getValidUserDocument(
          res.user.uid,
          res.user.email ?? '',
          {
            firstName: res.user.displayName
              ? res.user.displayName.split(' ')[0]
              : 'Anonymous',
            lastName: res.user.displayName?.split(' ').pop() ?? 'User',
          }
        );
        await createUserDocument(docData, s);
        queryClient.invalidateQueries(['user']);
      }
      if (router.query?.redirect) {
        router.push(router.query.redirect.toString());
      } else {
        router.push('/dashboard/account-overview');
      }
    }
  };

  return (
    <>
      <NextSeo
        title="Login to GearFocus - Access Your Camera Gear Marketplace"
        description="Log in to GearFocus to manage your listings and shop used camera gear. Connect with a community of photographers and creators in a secure marketplace."
        openGraph={{
          images: [
            {
              url: "https://www.gearfocus.com/gear_pile.png",
              width: 800,
              height: 600,
              alt: "Login to GearFocus - Access Your Camera Gear Marketplace",
              type: "image/jpeg",
            },
          ],
          siteName: "GearFocus Marketplace",
          type: 'website',
          url: "https://www.gearfocus.com/login",
        }}
      />

      <div 
        className="
          mt-[2.4rem] flex w-full items-center 
          justify-center px-[2.4rem] pb-[3.2rem] 
          sm:p-0 lg:mt-[3rem]
        "
      >
        {/* form */}
        <div className="w-full max-w-[36rem]">
          <h1 className="text-[2.4rem] font-semibold">Log in to GearFocus</h1>
          <div className="flex w-full text-[1.3rem]">
            <p>
              Don&apos;t have an account?&nbsp;
              <Link href="/signup" className="font-semibold text-brand-secondary">
                Sign Up
              </Link>
            </p>
          </div>

          {/* inputs and buttons */}

          <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
            {authError && (
              <div className="w-full text-center">
                <div className="m-auto w-fit justify-center rounded-[10px] border bg-red-200  px-[1.6rem] py-[0.8rem] font-medium text-red-900">
                  {authError}
                </div>
              </div>
            )}

            <form
              className="flex flex-col gap-[2rem]"
              onSubmit={handleSubmit(login)}
            >
              <FormLabel value="Email">
                <FormInput
                  type="email"
                  placeholder="Enter your email"
                  error={!!errors.email}
                  {...register('email')}
                  autoComplete="email"
                />
              </FormLabel>

              {/* password */}
              <FormLabel value="Password">
                <FormInput
                  type="password"
                  placeholder="Enter your password"
                  error={!!errors.password}
                  {...register('password')}
                  autoComplete="current-password"
                />
              </FormLabel>
              {/* forgot password link */}
              <div className="flex w-full flex-row-reverse text-input font-semibold text-brand-secondary">
                <Link
                  href="/forgot-password"
                  className="select-none focus:border-none focus:outline focus:outline-1"
                >
                  Forgot Password?
                </Link>
              </div>
              {/* login button */}
              <Button
                text={'Log in'}
                type="secondary"
                loading={isLoggingIn || isSubmitting}
                buttonType="submit"
              />
            </form>
            {/* divider */}
            <div className="flex h-[2.4rem] justify-between">
              <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
              <p>or continue with</p>
              <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
            </div>
            <SocialLoginButtons handleSocialClick={handleSocialClick} />
          </div>
          {/* terms of use & privacy policy */}
          <p className="mt-[2.4rem] text-tos lg:mt-[3.2rem]">
            By clicking Log in or Continue with Facebook, Apple or Google, your
            agree to GearFocus&apos;s&nbsp;
            <LinkTargetBlank href="/terms-and-conditions">
              Terms of Service
            </LinkTargetBlank>
            &nbsp;and&nbsp;
            <LinkTargetBlank href="/privacy-policy">
              Privacy Policy
            </LinkTargetBlank>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
