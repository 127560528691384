import Link from 'next/link';
import { useRouter } from 'next/router';
import { LeftArrowIcon, GearIcon, IconLogo } from './icons';

const MinimalNav = () => {
  const router = useRouter();
  const getBackPath = () => {
    if (router.query?.redirect) {
      return router.query.redirect.toString();
    }
    return '/';
  };
  return (
    <div className="w-full flex">
      {/* mobile */}
      <div className="lg:hidden w-full mb-[3rem]">
        <div className="relative flex h-[6.8rem] w-full items-center bg-brand-secondary px-[2.4rem]">
          <Link href={getBackPath()} className="absolute text-white">
            <LeftArrowIcon />
          </Link>
          <div className="flex w-full items-center justify-center text-brand-white">
            <GearIcon />
          </div>
        </div>
      </div>
      {/* desktop */}
      <div className="hidden lg:inline-block w-full max-w-[36rem] mx-auto">
        <div className="mt-[7.5rem]">
          <Link href="/">
            <IconLogo variant="default" height={40} width={40} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MinimalNav;
