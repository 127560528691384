import { IconLogo } from '@c/icons';
import MinimalNav from '@c/MinimalNav';
import SafeImage from '@ui/SafeImage';

type Props = {
  children: React.ReactNode;
};

export default function OnboardingLayout({ children }: Props) {
  return (
    <div className="h-full min-h-screen">
      <div className="flex">
        {/* left */}
        <div className="relative right-0 top-0 hidden min-h-screen w-full min-w-[48rem] max-w-[72rem] select-none overflow-clip lg:inline-block">
          <SafeImage
            alt="header image"
            src="/login.png"
            priority
            fill
            style={{ objectFit: 'cover' }}
          />
          <div className="absolute bottom-0">
            <div className="mb-[8rem] ml-[8rem] max-w-[56rem] text-brand-white">
              <p className="mt-[0.85rem] text-[4.4rem] font-semibold leading-[5.28rem] text-brand-secondary">
                BUY. SELL. CREATE.
              </p>
            </div>
          </div>
        </div>
        {/* right */}
        <div className="w-full">
          <MinimalNav />
          {children}
        </div>
      </div>
    </div>
  );
}
