import LoginForm from './forms/LoginForm';
import SignupForm from './forms/SignupForm';

interface Props {
  variant: 'login' | 'signup';
}

const LoginOrSignupPage = ({ variant }: Props) => {
  return (
    <div className="w-full">
      {variant === 'login' ? <LoginForm /> : <SignupForm />}
    </div>
  );
};

export default LoginOrSignupPage;
